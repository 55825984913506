<template>
  <div class="container">
    <loader v-if="isLoading" />

    <form action @submit.prevent="confirmPost">
      <div class="login-header">
        <div style="display: flex; align-items: center; justify-content: center;margin: 15px;">
          <img src="../../assets/images/logo.fw.png" alt="logo" />
          <span>
            <h3>NORAH : Check-Out</h3>
          </span>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <h3 class="title">Merchant</h3>

          <div v-if="merchant">
            <div class="login-header" v-if="merchant.logo">
              <img :src="merchant.logo" alt="Merchant Logo" style="height: 150px; width: auto;" />
            </div>
            <div class="inputBox">
              <span>Full Name:</span>
              <b>{{ merchant.name }}</b>
              <!-- Assuming API returns a name field -->
            </div>
            <div class="inputBox">
              <span>Email:</span>
              <b>{{ merchant.email }}</b>
              <!-- Assuming API returns an email field -->
            </div>
            <div class="inputBox">
              <span>Address:</span>
              <b>{{ merchant.address }}</b>
              <!-- Assuming API returns an address field -->
            </div>
            <!-- Add more fields as per your API response -->
          </div>
        </div>

        <div class="col">
          <h3 class="title">Payment Method</h3>

          <div class="inputBox">
            <span>
              Select Method :
              <b>{{ paymentMethod }}</b>
            </span>
            <img
              @click="paymentType('VISA')"
              style="margin: 5px;"
              src="../../assets/images/payments/visa.png"
              alt="Visa Card"
            />
            <img
              @click="paymentType('MASTERCARD')"
              style="margin: 5px;"
              src="../../assets/images/payments/mastercard.png"
              alt="Master Card"
            />
            <img 
              @click="paymentType('Bank Transfer')" 
              style="margin: 5px;" src="../../assets/images/payments/banktransfer.fw.png" 
              alt="Bank Transfer"
              />
            <img
              @click="paymentType('ECOCASH')"
              style="margin: 5px;"
              src="../../assets/images/payments/ecocash.png"
              alt="Ecocash"
            />
            <!-- <img
              @click="paymentType('ZIMSWITCH')"
              style="margin: 5px;"
              src="../../assets/images/payments/zimswitch.png"
              alt="Zipit"
            />  -->
            <img
              @click="paymentType('INNBUCKS')"
              style="margin: 5px;"
              src="../../assets/images/payments/innbucks.jpeg"
              alt="Zipit"
            /> 
            
          </div>
          <section v-if="paymentMethod == 'ECOCASH'">
            <div class="inputBox">
              <span>Payment Narration :</span>
              <input v-model="narration" type="text" placeholder="Ref : " />
            </div>
            <div class="inputBox">
              <span>Econet number :</span>
              <input v-model="phone" type="number" placeholder="0772123321" />
            </div>
          </section>
          <section v-if="paymentMethod == 'INNBUCKS'">
            <div class="inputBox">
              <span>Payment Narration :</span>
              <input v-model="narration" type="text" placeholder="Ref : " />
            </div>
            <div class="inputBox">
              <span>Econet number :</span>
              <input v-model="phone" type="number" placeholder="0772123321" />
            </div>
          </section>
          <section
            v-if="paymentMethod == 'VISA' || paymentMethod == 'MASTERCARD'"
          >
            <div class="inputBox">
              <span>name on card :</span>
              <input type="text" v-model="cardName" />
            </div>
            <div class="inputBox">
              <span>credit card number :</span>
              <input type="number" v-model="pan" />
            </div>

            <div class="flex">
              <div class="inputBox">
                <span>exp MM/YY :</span>
                <input type="number" v-model="expiryDate" />
              </div>
              <div class="inputBox">
                <span>CVV :</span>
                <input type="password" v-model="cardSecurityCode" />
              </div>
            </div>
          </section>
          <section
            v-if="paymentMethod == 'ZIMSWITCH' && checkoutId != ''"
            style="width: 350px;height: 285px;"
          >
          <form :action="`http://localhost:8080/#/check-out`" style="width: 350px; height: 285px;" class="paymentWidgets" data-brands="PRIVATE_LABEL" method="POST" target="cnpIframe" lang="en">
          </form>
          <div id="payment-widget-container"></div>

            
          </section>
          
          <section v-if="paymentMethod == 'Bank Transfer'">
            <div class="inputBox">
              <span>name of Account :</span>
              <input type="text" placeholder="mr. john deo" />
            </div>
            <div class="inputBox">
              <span>account number :</span>
              <input type="number" placeholder="660123654789" />
            </div>
            <div class="inputBox">
              <span>Transaction Ref :</span>
              <input type="number" placeholder="F111-G222-3333" />
            </div>
          </section>
          <section v-if="paymentMethod == 'Zipit'">
            <div class="inputBox">
              <span>name of Account :</span>
              <input type="text" placeholder="mr. john deo" />
            </div>
            <div class="inputBox">
              <span>account number :</span>
              <input type="number" placeholder="660123654789" />
            </div>
            <div class="inputBox">
              <span>Transaction Ref :</span>
              <input type="number" placeholder="F111-G222-3333" />
            </div>
          </section>
        </div>
      </div>

      <input
        v-if="this.charges != '0.00'"
        :value="`Charges : ${this.currency} ${parseFloat(this.charges).toFixed(2)}`"
        class="charges-btn"
      />
      <input
        type="submit"
        :value="isSubmitting ? 'Processing...' : `Total Amount : ${this.currency} ${parseFloat(this.amount).toFixed(2)}`"
        class="submit-btn"
        :class="{ 'loading': isSubmitting }"
        :disabled="isSubmitting"
      />
    </form>
  </div>
</template>
<script>
import Loader from "../Layouts/Loader.vue";
export default {
  name: "CheckOut",
  components: {
    Loader
  },
  data: function() {
    //4456530000001005
    //1025
    //134
    // develop branch 
    return {
      isLoading: false,
      paymentMethod: "VISA",
      qrCode: 'test',
      merchant: null,
      phone:"",
      isSubmitting: false,
      pan: "",
      narration:"",
      expiryDate: "",
      cardSecurityCode: "",
      returnUrl:"",
      amount: "0.00",
      cents: "",
      charges: "0.00",
      currency: "USD",
      cardName: "",
      zimswitchId: "",
      zimswitchResource: "",

      headerText: '',
      payType: '',
      paySource: '',
      checkoutId: '',
      shopperUrl: '/',
      checkoutUrl: 'https://eu-test.oppwa.com/v1/paymentWidgets.js?checkoutId=',
      authData: {
        entityId: "8ac7a4c98ac93d3b018ad08665a1066f",
        authorizationBearer: "Bearer OGFjN2E0Yzk4YWM5M2QzYjAxOGFkMDgyNTc5NTA2NmJ8aERjWG1YUUN3WVl0NGJhaw==",
        oppwaUrl: "https://eu-test.oppwa.com/v1/checkouts",
        payType: "DB",
        shopperUrl: "/",
        productionMode: false,
        resultPage: "/pay_result.php",
        baseUrl: "https://eu-test.oppwa.com",
        checkoutUrl: "eu-test.oppwa.com/v1/paymentWidgets.js?checkoutId="
      }
    };
  },
  created() {
     
    // this.makeRequest();
  },
  mounted (){
    this.fetchMerchantInfo();
    this.requestCheckoutId(this.authData.authorizationBearer, this.authData.entityId, this.authData.oppwaUrl, this.authData.payType, this.currency, this.amount)
    
  },
  watch: {
    '$route.query.id'(newId, oldId) {
      // Check if the new value is different from the old value
      if (newId !== oldId) {
        //this.refreshBrowser();
      }
    }
  },
  methods: {
    refreshBrowser() {
      // Reload the current page
      window.location.reload();
    },
    loadPaymentWidget() {
      
    },
    paymentType(type) {
      this.paymentMethod = type;
      if(this.paymentMethod == "ZIMSWITCH"){
        this.requestCheckoutId(this.authData.authorizationBearer, this.authData.entityId, this.authData.oppwaUrl, this.authData.payType, this.currency, this.amount)
      
      }
    },
    makeRequest() {
      const form = document.createElement('form');
      form.method = 'POST';
      form.action = 'https://portal.host.iveri.com/threedsecure/EnrollmentInitial';
      //form.target = '_blank'; // This will open the form submission in a new tab

      const params = {
        'ReturnUrl': 'http://localhost:8080/',
        'ApplicationID': '{2e2f4753-5d6e-4f49-a608-133292406cb1}',
        'Amount': '240',
        'Currency': 'USD',
        'MerchantReference': '1111111111',
        'PAN': '4069425217889137',
        'ExpiryDate': '1025'
      };

      for (const key in params) {
        if (params.hasOwnProperty(key)) {
          const hiddenField = document.createElement('input');
          hiddenField.type = 'hidden';
          hiddenField.name = key;
          hiddenField.value = params[key];
          form.appendChild(hiddenField);
        }
      }

      const cookieField = document.createElement('input');
      cookieField.type = 'hidden';
      cookieField.name = 'Cookie';
      cookieField.value = '__RequestVerificationToken=gAbO2YZUhe4SHHWW2itfpMWdmKMa8fV6SDUX2kM6RgCi5lhQpsYUzM7U8QmtikXbR0We9CUrwNy4dtt8HseLAlqvmrE6VI9O89tnrrDgCAc1';
      form.appendChild(cookieField);

      document.body.appendChild(form);
      form.submit();
      document.body.removeChild(form); //
    },
    async confirmPost() {
      if(this.paymentMethod == "VISA" || this.paymentMethod == "MASTERCARD"){
        if(this.pan == "" || this.expiryDate == "" || this.cardSecurityCode == "" || this.cardName == ""){
          this.$swal.fire(
              "Transaction error",
              "Please fill all the fields before confirming.",
              "error"
            );
        }else{
          this.isSubmitting = true;
        
        try {
          const response = await this.axios.post(
            "/api/v1/transaction/confirmation",
            {
              userId: this.merchant.userId,
              userName: this.merchant.userName,
              merchantUid: this.merchant.uid,
              trace: this.merchant.trace,
              currency: this.merchant.currency,
              amount: this.merchant.amount,
              charges: this.merchant.charges,
              pan: this.pan,
              expiryDate: this.expiryDate,
              cardSecurityCode: this.cardSecurityCode,
              paymentMethod: this.paymentMethod,
              type: this.merchant.type,
            }
          );

          // Check if the API response contains merchant data
          if (response.data.status == "SUCCESS") {
            if(this.paymentMethod == "VISA" || this.paymentMethod == "MASTERCARD"){
              this.submitForm();
            }
            if(this.paymentMethod == "ZIMSWITCH"){
              this.initPayment();
            }
            
          } else {
            console.error("No merchant data returned from API.");
          }
        } catch (error) {
          console.error("Error fetching merchant information:", error);
          // Handle error appropriately
        } finally {
          this.isLoading = false;
        }

        }
      }
      if(this.paymentMethod == "ECOCASH"){
        this.isSubmitting = true;
        try {
          const response = await this.axios.post(
            "/api/v1/transaction/confirmation",
            {
              userId: this.merchant.userId,
              userName: this.merchant.userName,
              merchantUid: this.merchant.uid,
              trace: this.merchant.trace,
              currency: this.merchant.currency,
              amount: this.merchant.amount,
              charges: this.merchant.charges,
              pan: this.pan,
              expiryDate: this.expiryDate,
              cardSecurityCode: this.cardSecurityCode,
              paymentMethod: this.paymentMethod,
              type: this.merchant.type,
            }
          );

          // Check if the API response contains merchant data
          if (response.data.status == "SUCCESS") {
            this.submitForm();
            
          } else {
            console.error("No merchant data returned from API.");
          }
        } catch (error) {
          console.error("Error fetching merchant information:", error);
          // Handle error appropriately
        } finally {
          this.isLoading = false;
        }
        // open a new tab with the payment gateway
        // window.open(
        //   "/#/check-out-loading?phone="+this.phone+"&method="+this.paymentMethod,
        // );
        window.location.href = "/#/check-out-loading?phone=" + this.phone + "&method=" + this.paymentMethod;
      }
      if(this.paymentMethod == "INNBUCKS"){
        this.isSubmitting = true;
        try {
          const response = await this.axios.post(
            "/api/v1/transaction/confirmation",
            {
              userId: this.merchant.userId,
              userName: this.merchant.userName,
              merchantUid: this.merchant.uid,
              trace: this.merchant.trace,
              currency: this.merchant.currency,
              amount: this.merchant.amount,
              charges: this.merchant.charges,
              paymentMethod: this.paymentMethod,
              narration: this.narration,
              type: this.merchant.type,
            }
          );

          // Check if the API response contains merchant data
          if (response.data.status == "SUCCESS") {
             // open a new tab with the payment gateway
            const parsedCode = JSON.parse(response.data.additionalData);
            this.qrCode = parsedCode.code;
            // console.log(this.qrCode);
            this.isSubmitting = false;
            window.open(
              "/#/check-out-loading?phone="+this.phone+"&method="+this.paymentMethod+"&qrcode="+this.qrCode+"&reference="+this.merchant.trace,
              "_self"
            );
            
          } else {
            console.error("No merchant data returned from API.");
            this.$swal.fire(
            "Transaction error",
            "No merchant data returned from API.",
            "error"
          );
          this.isSubmitting = false;
          }
        } catch (error) {
          console.error("Error fetching merchant information:", error);
          this.$swal.fire(
            "Transaction error",
            "Error fetching merchant information: Innbuck system is down",
            "error"
          );
          this.isSubmitting = false;
          // Handle error appropriately
        } finally {
          this.isSubmitting = false;
        }
         
      }

    
    },
    submitForm() {
      
      var form = document.createElement("form");
      form.setAttribute("method", "post");
      form.setAttribute(
        "action",
        "https://portal.host.iveri.com/threedsecure/EnrollmentInitial"
      );
      

      // Create hidden input fields and append them to the form
      var fields = {
        // ReturnUrl: this.merchant.returnUrl,
        ReturnUrl: "https://www.npg.co.zw",
        // ReturnUrl: "https://1367-41-85-218-138.ngrok-free.app",
        ApplicationID: "2e2f4753-5d6e-4f49-a608-133292406cb1",
        // MerchantData: this.$route.query.token,
        Amount: this.cents,
        Currency: this.currency,
        MerchantReference: this.merchant.trace,
        PAN: this.pan,
        ExpiryDate: this.expiryDate,
        CardSecurityCode: this.cardSecurityCode
      };

      for (var key in fields) {
        if (fields.hasOwnProperty(key)) {
          var input = document.createElement("input");
          input.setAttribute("type", "hidden");
          input.setAttribute("name", key);
          input.setAttribute("value", fields[key]);
          form.appendChild(input);
        }
      }
      // Add form to document body
      document.body.appendChild(form);

      // Submit the form
      form.submit();
    },
    async fetchMerchantInfo() {
      this.isLoading = true;

      const token = this.$route.query.token;
      const type = this.$route.query.type;

      const zimswitchId = this.$route.query.id;
      const zimswitchResource = this.$route.query.resourcePath;

      if (!token) {
        console.error("No token found in URL.");
        this.isLoading = false;
        
      }
      if (!type) {
        console.error("No type found in URL.");
        this.isLoading = false;
        
      }
      if (!zimswitchId) {
        console.error("No zimswitch id found in URL.");
        this.isLoading = false;
        
      }
      if (!zimswitchResource) {
        console.error("No zimswitch resource found in URL.");
        this.isLoading = false;
        
      }

      if(token && type){
        try {
        const response = await this.axios.post("/api/v1/transaction/checkout", {
          token: token,
          type: type
        });

        // Check if the API response contains merchant data
        if (response.data) {
          this.merchant = {
            uid: response.data.uid,
            name: response.data.merchantName,
            email: response.data.merchantEmail,
            address: response.data.merchantAddress,
            phone: response.data.merchantPhone,
            logo: response.data.merchantLogo,
            returnUrl: response.data.returnUrl,
            amount: response.data.amount,
            charges: response.data.charges,
            currency: response.data.currency,
            trace: response.data.trace,
            status: response.data.status,
            cents: response.data.cents,
            userId: response.data.userId,
            userName: response.data.userName,
            type: response.data.type,
           
          };
          this.currency = this.merchant.currency;
          this.amount = this.merchant.amount;
          this.cents = this.merchant.cents;
          this.charges = this.merchant.charges;
          this.returnUrl = this.merchant.returnUrl;
        } else {
          this.$swal.fire(
            "Transaction error",
            "No merchant data returned from API.",
            "error"
          );
        }
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        if (error.response) {
          // Server responded with a status other than 200 range
          console.error("Error fetching merchant information:", error.response);
          if(error.response.data.message == "JWT token has expired"){
            this.$swal.fire(
            "Transaction error!",
            "This transaction has expired. Please initiate a new transaction and complete it within the allotted time.",
            "error"
          );
          }else{
            this.$swal.fire(
            "Transaction error!",
            `${error.response.data.message || "An error occurred"}`,
            "error"
          );
          }
          
        } else if (error.request) {
          // The request was made but no response was received
          console.error("No response was received", error.request);
          this.$swal.fire("Network error", "No response from server", "error");
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error("Error", error.message);
          this.$swal.fire("Error", error.message, "error");
        }
      } finally {
        this.isLoading = false;
      }
      }

      else if(zimswitchId && zimswitchResource){
        this.zimswitchId = zimswitchId;
        this.zimswitchResource = zimswitchResource;
        this.isLoading = false;
        // this.submitFormZimswitch();
      }else{
        this.isLoading = false;
      }
      


    },

    async requestCheckoutId(bearer, entityID, oppwaURL, paytype, currency, amount) {
  try {
    const data = new URLSearchParams({
      entityId: entityID,
      amount: amount,
      currency: currency,
      paymentType: paytype
    });

    data.append('testMode', 'EXTERNAL');

    const headers = {
      'Authorization': bearer,
      'Content-Type': 'application/x-www-form-urlencoded'
    };

    const response = await this.axios.post(oppwaURL, data, { headers });
    // Assuming the response structure has a checkoutId field
    this.checkoutId = await response.data.id; // Adjust according to your API response
    //console.log(this.checkoutId);
    
    
      if(document.getElementById('payment-widget-container')){
        const container = document.getElementById('payment-widget-container');
     
        const script = document.createElement('script');
        script.src = `https://${this.authData.checkoutUrl}${this.checkoutId}`;
        script.async = true;

      
         container.append(script);
        //  document.querySelector('input[name="shopperResultUrl"]').value = "https://new-url.com";
         console.log("hi");
         console.log(script);
        
      }
      
      
  } catch (error) {
    console.error('Error fetching checkout ID:', error);
    throw error; // Rethrow the error to handle it further up the call stack
  }
    },

    submitFormZimswitch() {
      const url = this.authData.baseUrl + this.zimswitchResource + "?entityId=" + this.zimswitchId;

      // // Data to be sent
      // const data = {
      //   entityId: this.authData.entityId,
      //   amount: this.amount,
      //   currency: this.currency,
      //   paymentType: this.authData.payType,
      //   // testMode: this.authData.payMode,
      //   id: this.zimswitchId,
      //   shopperUrl: this.shopperUrl
      // };

      // Axios request options
      const options = {
        headers: {
          'Authorization': `Bearer ${this.authData.authorizationBearer}`,
          'Content-Type': 'application/x-www-form-urlencoded' // If required
        }
      };

      // Fetch the URL with axios
      this.axios.get(url, options)
        .then(response => {
          // const newWindow = window.open();
          // newWindow.document.open();
          // newWindow.document.write(response.data);
          // newWindow.document.close();
          console.log(response.data)
        })
        .catch(error => console.error('Error:', error));
    },
    cancel() {
      window.location.href = '/';
    }
    
  }
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600&display=swap");

* {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  border: none;
  text-transform: capitalize;
  transition: all 0.2s linear;
}

.loading {
  opacity: 0.75;
  cursor: not-allowed;
}

/* Optional: simple CSS animation */
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.loading::after {
  content: "";
  width: 16px;
  height: 16px;
  border: 2px solid #fff;
  border-top-color: #333;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  animation: spinner 0.6s linear infinite;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 25px;
  min-width: 100%;
  min-height: 100vh;
  background: linear-gradient(90deg, #010647 60%, #303795 40%);
}

.container form {
  padding: 20px;
  width: 800px;
  background: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

.container form .row {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.container form .row .col {
  flex: 1 1 250px;
}

.container form .row .col .title {
  font-size: 20px;
  color: #333;
  padding-bottom: 5px;
  text-transform: uppercase;
}

.container form .row .col .inputBox {
  margin: 15px 0;
}

.container form .row .col .inputBox span {
  margin-bottom: 10px;
  display: block;
}

.container form .row .col .inputBox input {
  width: 100%;
  border: 1px solid #ccc;
  padding: 10px 15px;
  font-size: 15px;
  text-transform: none;
}

.container form .row .col .inputBox input:focus {
  border: 1px solid #000;
}

.container form .row .col .flex {
  display: flex;
  gap: 15px;
}

.container form .row .col .flex .inputBox {
  margin-top: 5px;
}

.container form .row .col .inputBox img {
  height: 34px;
  margin-top: 5px;
  filter: drop-shadow(0 0 1px #000);
}

.container form .submit-btn {
  width: 100%;
  padding: 12px;
  font-size: 17px;
  background: #010647;
  color: #fff;
  margin-top: 5px;
  cursor: pointer;
}
.container form .charges-btn {
  width: 100%;
  padding: 12px;
  font-size: 17px;
  background: #727272;
  color: #fff;
  margin-top: 5px;
  text-align: center;
}

.container form .submit-btn:hover {
  background: #303795;
}

img:hover {
  cursor: pointer;
}
</style>